import { createContext, useState } from "react";

const Context = createContext();

const ContextProvider = ({ children }) => {
  const [aboutside, setAboutSide] = useState(false);
  const [lan, setLan] = useState(localStorage.getItem("lang") || "ru");
  const [refi, setRefi] = useState(false);
  const [moskovFilter, setMoskovFilter] = useState(false);
  return (
    <Context.Provider
      value={{
        lan,
        setLan,
        refi,
        setRefi,
        aboutside,
        setAboutSide,
        setMoskovFilter,
        moskovFilter,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
