import React from "react";
import { useMask } from "@react-input/mask";

const PhoneInput = ({ value, onChange, className }) => {
  const inputRef = useMask({
    mask: "+7 (___) ___-__-__",
    replacement: { _: /\d/ },
  });

  return (
    <input
      className={className}
      ref={inputRef}
      value={value}
      onChange={onChange}
      placeholder="+7 (___) ___-__-__"
      type="tel"
    />
  );
};

export default PhoneInput;
