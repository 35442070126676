import { useEffect } from "react";
import Authenfication from "./auth/Authenfication";
import Unauthenfication from "./auth/Unauthenfication";
import { useLocation } from "react-router-dom";
let token = localStorage.getItem("token");
function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  if (token) {
    return <Authenfication />;
  } else {
    return <Unauthenfication />;
  }
}

export default App;
