import React from "react";

const Catalogtype = () => {
  return (
    <div className="">
      <ul className="catalog__hoverimg-list">
        <li className="baclist1">
          <a className="catalog__hovera1  " href="">
            <div className="catalog__hover-text">Москва</div>
          </a>
        </li>
        <li className=" baclist4 ">
          <a className="catalog__hovera1" href="">
            <div className="catalog__hover-text">Индонезия</div>
          </a>
        </li>
        <li className=" baclist3">
          <a className="catalog__hovera1" href="">
            <div className="catalog__hover-text">Тайланд</div>
          </a>
        </li>
        {/* <li
      className="baclist5"
      >
        <a className="catalog__hovera1" href="">
          <div className="catalog__hover-text">Турция</div>
        </a>
      </li> */}
        <li className=" baclist2 ">
          <a className="catalog__hovera1" href="">
            <div className="catalog__hover-text">Дубай</div>
          </a>
        </li>
        <li className=" baclist4 ">
          <a className="catalog__hovera1" href="">
            <div className="catalog__hover-text">Абу-Даби</div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Catalogtype;
