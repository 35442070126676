import React from 'react'
import './arrow.css'
import arrowup from '../../assets/img/arrowup.svg'
const Arrowup = () => {
  return (
    <a className='arrowup' href='#he'>
        <img width={30} src={arrowup} alt="" />
    </a>
  )
}

export default Arrowup